// projects.js
const projects = [
    {
      title: "DragonTagsV2",
      description: "Come up with awesome tags to display alongside a player's name during chat!",
      imageUrl: "https://camo.githubusercontent.com/14c012c56b4e2dd2acc3f8f7e3807a015cd8ceec663cdef0d4f7fb8a0b7d777f/68747470733a2f2f692e706f7374696d672e63632f794e4433427474742f53637265656e73686f742d323032342d31322d32352d3031313535322e706e67",
      category: "Backend",
      carousel: true
    },
    {
      title: "Project Two",
      description: "Description for project two.",
      imageUrl: "https://via.placeholder.com/150",
      category: "Web Development",
    
    },
    {
      title: "Project Three",
      description: "Description for project three.",
      imageUrl: "https://via.placeholder.com/150",
      category: "Mobile Apps",
   
    },
    {
      title: "Project Four",
      description: "Description for project four.",
      imageUrl: "https://via.placeholder.com/150",
      category: "Meow",
      carousel: true
    },
    {
      title: "Project Five",
      description: "Description for project five.",
      imageUrl: "https://via.placeholder.com/150",
      category: "Web Development",
     
    },
  ];
  
  export default projects;
  